import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { UserInformationResult } from "services/accountService/models/AccountType";

const PersonalInfo = ({ user }: { user?: UserInformationResult | null }) => {
  const { t } = useTranslation("UserProfile");
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  return (
    <div className="striped d-flex flex-column position-relative ">
      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("nationalId")}</div>
        <div className="flex-2">{user?.nationalId}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("phoneNumber")}</div>
        <div className="flex-2 phone-number">{user?.phoneNumber}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("workPhoneNumber")}</div>
        <div className="flex-2 phone-number">{user?.workPhoneNumber}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("email")}</div>
        <div className="flex-2">{user?.email}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("nationality")}</div>
        <div className="flex-2">{user?.nationality}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("gender")}</div>
        <div className="flex-2">{user?.gender}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("birthDate")}</div>
        <div className="flex-2">{user?.birthDate ? toSystemThemeDateFormat(user?.birthDate, true) : ""}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("address")}</div>
        <div className="flex-2">{user?.address}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("city")}</div>
        <div className="flex-2">{user?.city}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1 fw-bold text-dark">{t("postCode")}</div>
        <div className="flex-2">{user?.postCode}</div>
      </div>
    </div>
  );
};

export default PersonalInfo;
