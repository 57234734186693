import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ProgramLocalResult } from "services/programs/models/ProgramTypes";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { ProgramsService } from "services/programs";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import ActionModal from "../../../components/blocks/Modal/ActionModal";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";

export const programUserTypes = {
  Admin: "Admin",
  ViceDean: "ViceDean",
  FundingUnit: "FundingUnit",
  Researcher: "Researcher",
} as const;

export default function ProgramsGridView({
  userType,
  columnsToHide = [],
}: {
  userType: keyof typeof programUserTypes;
  columnsToHide?: string[];
}) {
  const { t } = useTranslation("Programs");
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();
  const { isAr } = useCookieContext();

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState<ProgramLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    type: "delete",
    ProgramName: "",
    ProgramId: 0,
  });

  const onModalConfirm = async () => {
    const res = await ProgramsService.deleteProgram(modalProps.ProgramId);
    if (res.hasError) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } else {
      showNotification({
        type: "success",
        description: t("Common:deleteSuccess"),
      });
      setModalProps({ ...modalProps, isOpen: false });
      fetchPrograms();
    }
  };

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchPrograms = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ProgramsService.getAllProgramsAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].value
                : filters[key],
            operator:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].operator
                : "Contains",
          })),
      });

      setPrograms(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchPrograms();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchPrograms({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allPrograms",
        path: "/home/Programs",
        menuItemId: "programs",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(() => {
    const columns: GridSchema[] = [
      {
        field: "SerialNumber",
        isHidden: true,
        filterable: true,
        displayName: t("serialNumber"),
      },
      {
        field: "ProgramDetail.DisplayName",
        displayName: t("programName"),
        render: (row: ProgramLocalResult) => <Link to={`${row.id}`}>{row.programDetail.displayName}</Link>,
        showOnMobile: true,
      },
      {
        accessor: "programType",
        field: "ProgramType",
        displayName: t("programTypeClass"),
        render: (row: ProgramLocalResult) => {
          return (
            <>
              {row.programType} - {row.programClassification}
            </>
          );
        },
      },
      {
        field: "AdsEndDate",
        displayName: t("adsClose"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render: (row: ProgramLocalResult) => {
          return <>{toSystemThemeDateFormat(row.adsEndDate)}</>;
        },
      },
      {
        accessor: "programOrders",
        field: "ProgramOrders",
        displayName: t("programOrders"),
      },
      {
        accessor: "fundingDurationInMonths",
        field: "FundingDurationInMonths",
        displayName: t("fundingDuration"),
        sortable: true,
        filterable: true,
      },
      {
        field: "Status",
        displayName: t("status"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "Completed", value: t("Completed") },
            { id: "Draft", value: t("Draft") },
          ],
        },
        render(row: ProgramLocalResult) {
          const statusClassName =
            row.status === "Completed"
              ? "bg-success bg-opacity-10 text-success"
              : "bg-primary bg-opacity-10 text-primary";
          return <span className={`badge rounded-4 ${statusClassName} py-2`}>{t(row.status)}</span>;
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        sortable: false,
        showOnMobile: true,
        render: (row: ProgramLocalResult) => {
          return (
            <>
              {userType === programUserTypes.Admin && (
                <>
                  <Link to={`/home/programs/form?id=${row.id}`} className="btn text-darkGray btn-sm">
                    <i className="icon-edit" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Edit")} />
                  </Link>

                  <span
                    role="button"
                    onClick={() => {
                      setModalProps({
                        isOpen: true,
                        type: modalProps.type,
                        ProgramName: row.programDetail.displayName,
                        ProgramId: row.id,
                      });
                    }}
                    className="btn text-danger btn-sm"
                  >
                    <i className="icon-delete" data-bs-toggle="tooltip" data-bs-placement="top" title={t("delete")} />
                  </span>
                </>
              )}

              {userType === programUserTypes.FundingUnit && (
                <Link to={`${row.id}`} className="btn text-primary btn-sm">
                  <i className="icon-view" />
                </Link>
              )}

              {userType === programUserTypes.ViceDean && (
                <Link to={`${row.id}`} className="btn text-primary btn-sm">
                  <i className="icon-view" />
                </Link>
              )}
            </>
          );
        },
      },
    ];

    return columns.filter((column) => !columnsToHide?.includes(column.field));
  }, [t, toSystemThemeDateFormat]);

  const viewHeader: GridViewHeaderProps = {
    title: t("allPrograms"),
    singularName: t("program"),
    addPageRoute: "/home/programs/form",
    totalCount: totalCount,
    onExportClick: async () => {
      setLoading(true);
      const response = await ProgramsService.exportProgramsAsync({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Programs.xlsx";
      document.body.appendChild(link);
      link.click();
      setLoading(false);
      link.remove();
    },
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: programs ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />

      <ActionModal
        isOpen={modalProps.isOpen}
        onClose={() => {
          setModalProps({
            isOpen: false,
            type: "delete",
            ProgramName: "",
            ProgramId: 0,
          });
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteProgramModalSubMsg", {
          ProgramName: modalProps.ProgramName,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("delete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onModalConfirm}
      />
    </>
  );
}
