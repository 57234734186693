import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { GridSchema } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useNotification } from "hooks/useNotification";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { UserProgramOrdersResult } from "services/orders/models/OrdersTypes";
import { useShallow } from "zustand/react/shallow";

type ApplicantsTabProps = {
  programId: number;
};

const ApplicantsTab: React.FC<ApplicantsTabProps> = (props) => {
  const { programId } = props;
  const { t } = useTranslation("Orders");
  const { showNotification } = useNotification();
  const [data, setData] = useState<UserProgramOrdersResult[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const ready = useRef(false);
  const { isAr } = useCookieContext();

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchProgramUserOrders = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await OrdersService.getOrderUsersByProgramId(+programId, {
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].value
                : filters[key],
            operator:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].operator
                : "Contains",
          })),
      });

      setData(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchProgramUserOrders();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchProgramUserOrders({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  const applicantsGridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "appliedUserName",
        displayName: t("applicantName"),
        showOnMobile: true,
      },
      {
        field: "degree",
        displayName: t("academicDegree"),
      },
      {
        field: "collage",
        displayName: t("collage"),
      },
      {
        field: "specialization",
        displayName: t("specialization"),
        showOnMobile: true,
      },
      {
        field: "email",
        displayName: t("email"),
      },
      {
        field: "phoneNumber",
        displayName: t("phone"),
        render: (row) => <span className="phone-number">{row.phoneNumber}</span>,
      },
      {
        field: "centreManagementName",
        displayName: t("researchesCenter"),
      },
    ],
    [],
  );

  return (
    <GridView
      loading={loading}
      viewHeaderProps={{
        singularName: t("applicant"),
        title: t("applicants"),
        totalCount: totalCount,
        hideAddButton: true,
      }}
      gridProps={{
        data: data ?? [],
        gridSchema: applicantsGridSchema,
      }}
    />
  );
};

export default ApplicantsTab;
