import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { EvaluationFormsService } from "services/evaluationFormsService";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import ActionModal from "components/blocks/Modal/ActionModal";
import { EvaluationFormLocalResult } from "services/evaluationFormsService/Models/evaluationFormsTypes";
import { LookupService } from "services/lookupService";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";

export default function EvaluationFormsGridView() {
  const { t } = useTranslation("EvaluationForms");
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();
  const { isAr } = useCookieContext();

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<EvaluationFormLocalResult[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [userTypes, setUserTypes] = useState<{ id: number; value: string }[]>([]);
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    type: "delete",
    name: "",
    id: 0,
    loading: false,
  });

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  useEffect(() => {
    fetchUserTypes();
  }, [isAr]);

  const fetchUserTypes = async () => {
    const userTypes = await LookupService.getAllUserTypes();
    const data = userTypes.data.map((userType) => ({
      id: userType.id,
      value: userType.userTypeDetails.displayName,
    }));

    setUserTypes(data);
  };

  const fetchData = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await EvaluationFormsService.getAllEvaluationForms({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: [
          ...Object.keys(filters)
            .filter((key) => filters[key])
            .map((key) => ({
              field: key,
              value:
                typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                  ? filters[key].value
                  : filters[key],
              operator:
                typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                  ? filters[key].operator
                  : "Contains",
            })),
          {
            field: "SystemTypeId",
            value: 1,
            operator: "Contains",
          },
        ],
      });

      setData(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchData();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchData({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  const onSwapFormOrder = async ({ from, to }: { from: number; to: number }) => {
    try {
      setLoading(true);
      const currentForm = data[from];
      const targetForm = data[to];

      const res = await EvaluationFormsService.swapOrder(
        {
          id: currentForm.id,
          order: currentForm.order,
        },
        {
          id: targetForm.id,
          order: targetForm.order,
        },
      );

      if (res.hasError) {
        showNotification({
          type: "error",
          description: t("Common:internalServerError"),
        });
      } else {
        fetchData();
      }
    } catch (error) {
      if (error instanceof Error) {
        showNotification({
          type: "notification",
          description: t(error.message),
        });
      } else {
        showNotification({
          type: "error",
          description: t("Common:internalServerError"),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "evaluationForms",
        path: "/home/evaluationForms",
        menuItemId: "evaluationForms",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "Order",
        accessor: "order",
        displayName: "#",
        filterDisplayValue: t("order"),
        sortable: true,
        filterable: true,
        showOnMobile: true,
      },
      {
        field: "FormName",
        displayName: t("formName"),
        showOnMobile: true,
        render: (row: EvaluationFormLocalResult) => {
          return row.evaluationFormDetail.displayName;
        },
      },
      {
        field: "SystemTypeId",
        displayName: t("systemType"),
        render(row: EvaluationFormLocalResult) {
          return <>{row.systemType.systemTypeDetails.displayName}</>;
        },
      },
      {
        field: "UserTypeId",
        filterable: true,
        filterOptions: {
          type: "select",
          options: userTypes ?? [],
        },
        displayName: t("userType"),
        render(row: EvaluationFormLocalResult) {
          return <>{row.userType.userTypeDetails.displayName}</>;
        },
      },
      {
        field: "CreatedOn",
        accessor: "createdOn",
        displayName: t("createdOn"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render(row: EvaluationFormLocalResult) {
          return <>{toSystemThemeDateFormat(row.createdOn)}</>;
        },
      },
      {
        field: "Status",
        accessor: "status",
        displayName: t("status"),
        render(row: EvaluationFormLocalResult) {
          return <>{t(`Common:${row.status}`)}</>;
        },
      },
      {
        field: "LastModifiedBy",
        accessor: "lastModifiedBy",
        displayName: t("lastModifiedBy"),
        render(row: EvaluationFormLocalResult) {
          return `${row.updatedBy} ${row.updatedOn ? `(${toSystemThemeDateFormat(row.updatedOn)})` : ""}`;
        },
      },
      {
        field: "Actions",
        displayName: t("Common:actions"),
        showOnMobile: true,
        render: (row: EvaluationFormLocalResult) => {
          return (
            <>
              <Link to={`/home/evaluationForms/form?id=${row.id}`} className="btn text-darkGray btn-sm">
                <i className="icon-edit" data-bs-toggle="tooltip" data-bs-placement="top" title={t("Edit")} />
              </Link>

              <span
                role="button"
                onClick={() => {
                  setModalProps({
                    isOpen: true,
                    type: modalProps.type,
                    name: row.evaluationFormDetail.displayName,
                    id: row.id,
                    loading: false,
                  });
                }}
                className="btn text-danger btn-sm"
              >
                <i className="icon-delete" data-bs-toggle="tooltip" data-bs-placement="top" title={t("delete")} />
              </span>
            </>
          );
        },
      },
    ],
    [t, userTypes],
  );

  const onExportClicked = async () => {
    setLoading(true);
    try {
      const response = await EvaluationFormsService.exportToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "evaluationForms.xlsx";
      document.body.appendChild(link);
      link.click();
      setLoading(false);
      link.remove();
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setLoading(false);
    }
  };

  const onDeleteConfirmed = async () => {
    setModalProps({ ...modalProps, loading: true });
    try {
      const res = await EvaluationFormsService.delete(modalProps.id);
      if (res.hasError) {
        showNotification({
          type: "error",
          description: t("Common:internalServerError"),
        });
      } else {
        showNotification({
          type: "success",
          description: t("Common:success"),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } finally {
      setLoading(false);
    }

    fetchData({ resetPagination: true });

    setModalProps({
      isOpen: false,
      type: "delete",
      name: "",
      id: 0,
      loading: false,
    });
  };

  const viewHeader: GridViewHeaderProps = {
    title: t("evaluationForms"),
    singularName: t("form"),
    addPageRoute: "/home/evaluationForms/form",
    totalCount: totalCount,
    onExportClick: onExportClicked,
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data,
          gridSchema,
          draggable: true,
          onRowDragEnd: onSwapFormOrder,
        }}
        viewHeaderProps={viewHeader}
      />

      <ActionModal
        isOpen={modalProps.isOpen}
        onClose={() => {
          setModalProps({
            isOpen: false,
            type: "delete",
            name: "",
            id: 0,
            loading: false,
          });
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteEvalFormModalSubMsg", {
          name: modalProps.name,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:delete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteConfirmed}
        loading={modalProps.loading}
      />
    </>
  );
}
