import GridView from "components/GridView";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useGridStore from "components/GridView/grid.store";
import IconButton from "components/blocks/IconButton";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useFileDownload from "hooks/useFileDownload";
import { useNotification } from "hooks/useNotification";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TasksService } from "services/tasks";
import { TaskResult } from "services/tasks/models/tasksTypes";
import ReAssignUserModal from "views/home/tasks/ReAssignUserModal";
import { useShallow } from "zustand/react/shallow";

const TasksTab = ({ userId }: { userId: string }) => {
  const { t } = useTranslation("Tasks");
  const navigate = useNavigate();
  const downloadFile = useFileDownload();
  const { toSystemThemeDateFormat } = useSystemDateTheme();

  const [reAssignModalProps, setReAssignModalProps] = useState<{
    isOpen: boolean;
    task: TaskResult | null;
    refreshTableTrigger: boolean;
  }>({
    isOpen: false,
    task: null,
    refreshTableTrigger: false,
  });

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [tasks, setTasks] = useState<TaskResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const { isAr } = useCookieContext();

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchTasks = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await TasksService.getTasksByUserId(
        {
          pageIndex: resetPagination ? 1 : pagination.currentPage,
          pageSize: 10,
          search: searchValue,
          sort: sortState
            ? Object.keys(sortState).map((key) => ({
                field: key,
                dir: sortState[key],
              }))
            : [],
          filter: Object.keys(filters)
            .filter((key) => filters[key])
            .map((key) => ({
              field: key,
              value:
                typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                  ? filters[key].value
                  : filters[key],
              operator:
                typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                  ? filters[key].operator
                  : "Contains",
            })),
        },
        userId,
      );

      setTasks(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchTasks();
  }, [pagination.currentPage, userId]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchTasks({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "ActivityName",
        displayName: t("taskName"),
        sortable: true,
        filterable: true,
        render: (row: TaskResult) => {
          return (
            <button className="btn btn-link" onClick={() => navigate(row.taskUrl)}>
              {row.activityName}
            </button>
          );
        },
      },
      {
        field: "WorkflowName",
        accessor: "workflowName",
        filterable: true,
        displayName: t("workflowName"),
        sortable: true,
      },
      {
        field: "taskDate",
        displayName: t("taskDate"),
        render: (row: TaskResult) => {
          return toSystemThemeDateFormat(row.startDateTime);
        },
      },
      {
        field: "description",
        displayName: t("description"),
      },
      {
        field: "originatorUserName",
        displayName: t("sender"),
        sortable: true,
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        sortable: false,
        render: (row: TaskResult) => {
          return (
            <span className="d-flex gap-2">
              <IconButton
                fitToIconWidth
                icon="icon-view"
                innerIconColor="primary"
                size="lg"
                onClick={() => navigate(row.taskUrl)}
              />

              <IconButton
                fitToIconWidth
                icon={"icon-reassign"}
                innerIconColor="dark"
                size="lg"
                onClick={() => {
                  setReAssignModalProps({
                    isOpen: true,
                    task: row,
                    refreshTableTrigger: reAssignModalProps.refreshTableTrigger,
                  });
                }}
              />
            </span>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("tasks"),
    singularName: t("task"),
    totalCount: totalCount,
    hideAddButton: true,
    /*  onExportClick: async () => {
      setLoading(true);
      const response = await TasksService.exportTasksToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].value
                : filters[key],
            operator:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].operator
                : "Contains",
          })),
      });
      await downloadFile(response, "tasks.xlsx");
      setLoading(false);
    }, */
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: tasks ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />

      <ReAssignUserModal
        isOpen={reAssignModalProps.isOpen}
        task={reAssignModalProps.task}
        onClose={(isReassigned) =>
          setReAssignModalProps({
            isOpen: false,
            task: null,
            refreshTableTrigger: isReassigned
              ? !reAssignModalProps.refreshTableTrigger
              : reAssignModalProps.refreshTableTrigger,
          })
        }
      />
    </>
  );
};

export default TasksTab;
