import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { GetAllMyProgramsOrderSignedStatusResult } from "services/orders/models/OrdersTypes";
import { OrdersService } from "services/orders";
import useFileDownload from "hooks/useFileDownload";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";

export default function MySignedWorkGridView() {
  const { t } = useTranslation("OrdersMyWork");
  const { showNotification } = useNotification();
  const downloadFile = useFileDownload();
  const { setBreadcrumbs } = useBreadcrumb();
  const { isAr } = useCookieContext();

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GetAllMyProgramsOrderSignedStatusResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchData = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await OrdersService.getAllMyProgramsOrderSignedStatus({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].value
                : filters[key],
            operator:
              typeof filters[key] === "object" && filters[key].hasOwnProperty("operator")
                ? filters[key].operator
                : "Contains",
          })),
      });

      setData(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchData();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchData({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "mySignedWork",
        path: "/home/orders/researcherOrders/myWork",
        menuItemId: "mySignedWork",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "orderSerialNumber",
        displayName: t("orderNumber"),
        showOnMobile: true,
        render: (row: GetAllMyProgramsOrderSignedStatusResult) => {
          return <Link to={`/home/orders/researcher/${row.id}`}>{row.orderSerialNumber}</Link>;
        },
      },
      {
        accessor: "programSerialNumber",
        field: "programSerialNumber",
        displayName: t("programNumber"),
        showOnMobile: true,
      },
      {
        field: "ProgramDetail.DisplayName",
        displayName: t("programName"),
        render: (row: GetAllMyProgramsOrderSignedStatusResult) => row.programDetail.displayName,
      },

      {
        field: "formSubject",
        displayName: t("suggestedTitle"),
      },
      {
        field: "CentreManagementDetail.DisplayName",
        displayName: t("researchCenter"),
        render: (row: GetAllMyProgramsOrderSignedStatusResult) => row.centreManagementDetail.displayName,
      },
    ],
    [t],
  );

  const onExportClicked = async () => {
    setLoading(true);
    try {
      const response = await OrdersService.exportMyProgramsOrderSignedStatusToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });
      await downloadFile(response, "MySignedProgramOrders.xlsx");
      setLoading(false);
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setLoading(false);
    }
  };

  const viewHeader: GridViewHeaderProps = {
    title: t("myWork"),
    singularName: t("work"),
    totalCount: totalCount,
    onExportClick: onExportClicked,
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: data ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
    </>
  );
}
